import router, { resetRouter } from "@/router/router";

export default function getMenu(e) {
  return new Promise((resolve) => {
    if (e && e.length != 0) {
      resetRouter();

      getMenuList(e).then((res) => {
        const menuList = res;
        // console.log(menuList)
        let index = {
          path: "/index",
          component: () => import("@/view/Index"),
          meta: {
            title: "首页",
            keep: false,
          },
          redirect: "/" + menuList[0].menuUrl,
        };
        let children = [];

        menuList.forEach((item) => {
          children.push({
            path: "/" + item.menuUrl,
            name: item.menuUrl,
            component: () =>
              import(
                "@/components/" +
                  item.menuComponent +
                  "/" +
                  (item.menuUrl == "TestKitScreening" ||
                  item.menuUrl == "FitScreening"
                    ? "ColorectalScreening"
                    : item.menuUrl)
              ),
            meta: {
              title: item.menuName,
              keep: setKeep(item.menuUrl),
            },
          });

          let child = {
            meta: {
              title: item.menuName + " - 详情",
              keep: setKeep(),
              pUrl: item.menuUrl,
            },
          };

          switch (item.menuUrl) {
            case "Order":
              children.push({
                path: "/OrderDeta",
                component: () =>
                  import("@/components/" + item.menuComponent + "/OrderDeta"),
                ...child,
              });
              break;
            case "MakeService":
              children.push({
                path: "/ServiceDeta",
                component: () =>
                  import("@/components/" + item.menuComponent + "/ServiceDeta"),
                ...child,
              });
              break;
            case "FollowService":
              children.push({
                path: "/CellDeta",
                component: () =>
                  import("@/components/" + item.menuComponent + "/CellDeta"),
                ...child,
              });
              break;
            case "OperateWork":
              children.push({
                path: "/OperateDeta",
                component: () =>
                  import("@/components/" + item.menuComponent + "/OperateDeta"),
                ...child,
              });
              children.push({
                path: "/OperateDetaRecord",
                component: () =>
                  import(
                    "@/components/" + item.menuComponent + "/OperateDetaRecord"
                  ),
                ...child,
              });
              break;
            case "Doctor":
              children.push({
                path: "/DoctorDeta",
                component: () =>
                  import("@/components/" + item.menuComponent + "/DoctorDeta"),
                ...child,
              });
              break;

            case "Sale":
              children.push({
                path: "/SaleDetails",
                component: () =>
                  import("@/components/" + item.menuComponent + "/SaleDetails"),
                ...child,
              });
              children.push({
                path: "/SaleAdd",
                component: () =>
                  import("@/components/" + item.menuComponent + "/SaleAdd"),
                meta: { ...child.meta, title: item.menuName + " - 添加" },
              });
              break;
            case "Product":
              children.push({
                path: "/ProductDeta",
                component: () =>
                  import("@/components/" + item.menuComponent + "/ProductDeta"),
                ...child,
              });
              break;
            case "ProductExa":
              children.push({
                path: "/ProductExaDeta",
                component: () =>
                  import(
                    "@/components/" + item.menuComponent + "/ProductExaDeta"
                  ),
                ...child,
              });
              break;
            case "Activity":
              children.push({
                path: "/ActivityDeta",
                component: () =>
                  import(
                    "@/components/" + item.menuComponent + "/ActivityDeta"
                  ),
                ...child,
              });
              break;
            case "Card":
              children.push({
                path: "/CardDeta",
                component: () =>
                  import("@/components/" + item.menuComponent + "/CardDeta"),
                ...child,
              });
              break;
            case "Role":
              children.push({
                path: "/RoleDetail",
                component: () =>
                  import("@/components/" + item.menuComponent + "/RoleDetail"),
                ...child,
              });
              break;
            case "TestKitList":
              children.push({
                path: "/TestKitDetail",
                component: () =>
                  import(
                    "@/components/" + item.menuComponent + "/TestKitDetail"
                  ),
                ...child,
              });
              break;
            case "ColorectalScreening":
              children.push({
                path: "/ColorectalScreeningDetails",
                name: "ColorectalScreeningDetails",
                component: () =>
                  import(
                    "@/components/" +
                      item.menuComponent +
                      "/ColorectalScreeningDetails"
                  ),
                ...child,
              });
              children.push({
                path: "/ColorectalScreeningAdd",
                name: "ColorectalScreeningAdd",
                component: () =>
                  import(
                    "@/components/" +
                      item.menuComponent +
                      "/ColorectalScreeningDetails"
                  ),
                meta: { ...child.meta, title: item.menuName + " - 添加" },
              });
              break;
            case "TestKitScreening":
              children.push({
                path: "/TestKitScreeningDetails",
                name: "TestKitScreeningDetails",
                component: () =>
                  import(
                    "@/components/" +
                      item.menuComponent +
                      "/ColorectalScreeningDetails"
                  ),
                ...child,
              });
              children.push({
                path: "/TestKitScreeningAdd",
                name: "TestKitScreeningAdd",
                component: () =>
                  import(
                    "@/components/" +
                      item.menuComponent +
                      "/ColorectalScreeningDetails"
                  ),
                meta: { ...child.meta, title: item.menuName + " - 添加" },
              });
              break;
            case "FitScreening":
              children.push({
                path: "/FitScreeningDetails",
                name: "FitScreeningDetails",
                component: () =>
                  import(
                    "@/components/" +
                      item.menuComponent +
                      "/ColorectalScreeningDetails"
                  ),
                ...child,
              });
              children.push({
                path: "/FitScreeningAdd",
                name: "FitScreeningAdd",
                component: () =>
                  import(
                    "@/components/" +
                      item.menuComponent +
                      "/ColorectalScreeningDetails"
                  ),
                meta: { ...child.meta, title: item.menuName + " - 添加" },
              });
              break;
            case "doctorManage":
              children.push({
                path: "/doctorManageAdd",
                name: "doctorManageAdd",
                component: () =>
                  import(
                    "@/components/" + item.menuComponent + "/doctorManageAdd"
                  ),
                meta: { ...child.meta, title: item.menuName + " - 添加" },
              });
              break;
            case "subjectAll":
              children.push({
                path: "/subjectAllDetails",
                name: "subjectAllDetails",
                component: () =>
                  import(
                    "@/components/" + item.menuComponent + "/subjectAllDetails"
                  ),
                ...child,
              });
              children.push({
                path: "/subjectEdit",
                name: "subjectEdit",
                component: () =>
                  import("@/components/" + item.menuComponent + "/subjectEdit"),
                ...child,
              });
              break;
            case "bowelPreparation":
              children.push({
                path: "/bowelPreparationDetails",
                name: "bowelPreparationDetails",
                component: () =>
                  import(
                    "@/components/" +
                      item.menuComponent +
                      "/bowelPreparationDetails"
                  ),
                ...child,
              });
              break;

            case "haiHeQuestionnaire":
              children.push({
                path: "/haiHeQuestionnaireDetail",
                name: "haiHeQuestionnaireDetail",
                component: () =>
                  import(
                    "@/components/" +
                      item.menuComponent +
                      "/haiHeQuestionnaireDetail"
                  ),
                ...child,
              });
              break;
          }
        });

        let Four = {
          path: "*",
          component: () => import("@/components/Four/Four"),
          meta: {
            title: "404",
            keep: setKeep(),
          },
        };

        index["children"] = children;

        router.addRoute(index);
        router.addRoute(Four);

        resolve(index);
      });
    } else {
      router.push({
        path: "/",
      });
    }
  });
}

function getMenuList(e) {
  return new Promise((resolve) => {
    let menuList = [];
    e.forEach((item) => {
      if (item.subMenu != null) {
        item.subMenu.forEach((items) => {
          menuList.push(items);
        });
        getMenu(item.subMenu);
      }
    });

    if (menuList.length != 0) {
      resolve(menuList);
    }
  });
}

function setKeep(e) {
  switch (e) {
    case "FitScreening":
    case "TestKitScreening":
    case "ColorectalScreening":
    case "TestKitList":
      return true;
    default:
      return false;
  }
}
