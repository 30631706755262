<template>
  <div>

    <template v-if="item.subMenu.length == 1">
      <el-menu-item :index="'/' + item.subMenu[0].menuUrl" class="submenu-title-noDropdown">
        <i class="sub-el-icon" :class="item.menuIcon" ></i>
        <template #title>{{ item.menuName }}</template>
      </el-menu-item>
    </template>

    <el-submenu v-else popper-append-to-body :index="item.id">
      <template #title>
        <i class="sub-el-icon" :class="item.menuIcon" ></i>
        <span>{{ item.menuName }}</span>
      </template>
      <el-menu-item :index="'/' + items.menuUrl" v-for="items in item.subMenu" :key="items.id" >
        {{ items.menuName }}</el-menu-item>
    </el-submenu>

  </div>
</template>

<script>
export default {
  name: "SidebarItem",
  data() {
    return{

    }
  },
  created() {

  },
  methods: {

  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1rem;
  height: 1rem;
}

::v-deep .el-submenu__icon-arrow{
  right: 12px;
}

::v-deep .sub-el-icon{
  margin-right: 5px !important;
}
</style>
